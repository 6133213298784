<template>
	<form type="POST" v-on:submit.prevent="storeProductosMarcas()" ref="productosMarcasForm">
		<h3 class="mb-3 text-primary">
			<font-awesome-icon :icon="faPlus" /> {{title}}
		</h3>
		<div class="card">
			<div class="card-body">
				<div class="mb-3">
					<label for="descripcion">Descripción:</label>
					<input type="text" name="descripcion" id="descripcion" class="form-control" placeholder="Ingrese descripción" v-model="form.DESCRIPCION_MARCA" maxlength="150" required>
					<span v-if="errors.DESCRIPCION_MARCA" class="text-danger">
						{{errors.DESCRIPCION_MARCA[0]}}
                    </span>
                </div>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-end align-items-center">
					<div class="me-2">
						<router-link :to="{name: 'admin.productos.marcas.index'}" class="btn btn-link">
							Cancelar
						</router-link>
					</div>
					<div>
						<div class="btn-group">
							<button type="submit" class="btn btn-primary" :disabled="loading || !validateForm">
								<BtnLoading v-if="loadingMarcas" />
								<span v-else>
									<font-awesome-icon :icon="faSave" /> Crear marca
								</span>
							</button>
							<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" :disabled="loading || !validateForm">
								<span class="visually-hidden">Más opciones</span>
							</button>
							<ul class="dropdown-menu dropdown-menu-end">
								<li>
									<a class="dropdown-item" href="#" v-on:click.prevent="storeProductosMarcas(true)">Crear y nuevo</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faSave, faPlus } from '@fortawesome/free-solid-svg-icons'
	import BtnLoading from '@/components/BtnLoading.vue'
	export default {
		components: {
			FontAwesomeIcon,
			BtnLoading
		},
		data(){
			return {
				faSave,
				faPlus,
				form: {
					DESCRIPCION_MARCA: ''
				},
				loadingMarcas: false,
				validateForm: false
			}
		},
		methods: {
			storeProductosMarcas(newItems=false){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingMarcas = true
				this.$store.dispatch('storeProductosMarcas', this.form).then((res) => {
					this.$toast.info(`El marca del producto ha sido creado`)
					if (newItems) {
						this.form.DESCRIPCION_MARCA = ''
					}else{
						this.$router.push({name: 'admin.productos.marcas.index'})
					}
					return res
				}).catch(err => {
					if(err.response){
						if(typeof err.response.data === 'string'){
							this.$toast.error(err.response.data)
						}else{
							this.$toast.error('Error al crear registro')
						}
					}
				}).then(() => {
					this.loadingMarcas = false
				})
			},
			checkValidity(){
				if (!this.$refs.productosMarcasForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.productosMarcasForm.checkValidity()
				}
			},
		},

		computed:{
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			loading() {
				return this.$store.getters.loading
			},

			errors() {
				return this.$store.getters.errors || {}
			}
		},
		watch: {
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>